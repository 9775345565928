<template>
  <p>
    <span class="leading_words"><slot></slot></span>
  </p>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.leading_words {
  font-weight: normal;
  font-size: 1.4em;
  @media only screen and (max-width: $breakpoint-phablet) {
    font-size: 1.3em;
  }
}
</style>
